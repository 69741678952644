<script setup lang="ts">

</script>

<template>
    <div class="tag">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
    .tag {
        @apply w-fit bg-[color:var(--orange-500)] px-3 py-[2px] rounded-md text-sm font-medium text-[color:var(--orange-600)];
    }
</style>